import i18n from '../locales/index'
export default {
	"abi": [
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "target",
					"type": "address"
				}
			],
			"name": "AddressEmptyCode",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "ERC1967InvalidImplementation",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "ERC1967NonPayable",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "FailedInnerCall",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "InvalidInitialization",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "NotInitializing",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "owner",
					"type": "address"
				}
			],
			"name": "OwnableInvalidOwner",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "OwnableUnauthorizedAccount",
			"type": "error"
		},
		{
			"inputs": [],
			"name": "UUPSUnauthorizedCallContext",
			"type": "error"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "slot",
					"type": "bytes32"
				}
			],
			"name": "UUPSUnsupportedProxiableUUID",
			"type": "error"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "account",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "refer",
					"type": "address"
				}
			],
			"name": "BindRefer",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "usdt",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "token",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256[]",
					"name": "amountOut",
					"type": "uint256[]"
				}
			],
			"name": "BuyStaticToken",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "usdt",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "token",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amountOut",
					"type": "uint256"
				},
				{
					"components": [
						{
							"internalType": "bytes",
							"name": "path",
							"type": "bytes"
						},
						{
							"internalType": "address",
							"name": "recipient",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "amountIn",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "amountOutMinimum",
							"type": "uint256"
						}
					],
					"indexed": false,
					"internalType": "struct ISwapRouter.ExactInputParams",
					"name": "params",
					"type": "tuple"
				}
			],
			"name": "BuyStaticToken",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint64",
					"name": "version",
					"type": "uint64"
				}
			],
			"name": "Initialized",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "previousOwner",
					"type": "address"
				},
				{
					"indexed": true,
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "OwnershipTransferred",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "index",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "address",
					"name": "sender",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amount",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amountOut",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amtTotal",
					"type": "uint256"
				}
			],
			"name": "Pledge",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "address",
					"name": "to",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint8",
					"name": "level",
					"type": "uint8"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "percent",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "income",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "len",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "inspair",
					"type": "uint256"
				}
			],
			"name": "StarPower",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": true,
					"internalType": "address",
					"name": "implementation",
					"type": "address"
				}
			],
			"name": "Upgraded",
			"type": "event"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "amount",
					"type": "uint256"
				}
			],
			"name": "WithdrawStaticToken",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "UPGRADE_INTERFACE_VERSION",
			"outputs": [
				{
					"internalType": "string",
					"name": "",
					"type": "string"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"components": [
						{
							"internalType": "address",
							"name": "account",
							"type": "address"
						},
						{
							"internalType": "uint8",
							"name": "level",
							"type": "uint8"
						}
					],
					"internalType": "struct ClockWise.UserLevel[]",
					"name": "users",
					"type": "tuple[]"
				}
			],
			"name": "batchUpdateLevel",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "idx",
					"type": "uint256"
				}
			],
			"name": "clearIncome",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "cwReward",
			"outputs": [
				{
					"internalType": "contract ClockWiseReward",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "cwStorage",
			"outputs": [
				{
					"internalType": "contract ClockWiseStorage",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "bytes32",
					"name": "_messageHash",
					"type": "bytes32"
				}
			],
			"name": "getEthSignedMessageHash",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "pure",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "idx",
					"type": "uint256"
				}
			],
			"name": "getIncomes",
			"outputs": [
				{
					"internalType": "address[]",
					"name": "",
					"type": "address[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "clockWiseStorage_",
					"type": "address"
				},
				{
					"internalType": "address",
					"name": "clockWiseReward_",
					"type": "address"
				}
			],
			"name": "initialize",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "owner",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_amt",
					"type": "uint256"
				},
				{
					"internalType": "bytes",
					"name": "_path",
					"type": "bytes"
				},
				{
					"internalType": "uint256",
					"name": "_minAmountOut",
					"type": "uint256"
				},
				{
					"components": [
						{
							"internalType": "enum ClockWiseStorage.RewardType",
							"name": "rewardType",
							"type": "uint8"
						},
						{
							"internalType": "address",
							"name": "sender",
							"type": "address"
						},
						{
							"internalType": "address",
							"name": "refer",
							"type": "address"
						},
						{
							"internalType": "address",
							"name": "pendingUser",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "amount",
							"type": "uint256"
						}
					],
					"internalType": "struct ClockWise.PairReward[]",
					"name": "rewardList",
					"type": "tuple[]"
				},
				{
					"internalType": "bytes",
					"name": "signature",
					"type": "bytes"
				}
			],
			"name": "pledge",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "proxiableUUID",
			"outputs": [
				{
					"internalType": "bytes32",
					"name": "",
					"type": "bytes32"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "refer",
					"type": "address"
				}
			],
			"name": "register",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "renounceOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "idx",
					"type": "uint256"
				},
				{
					"internalType": "address[]",
					"name": "_incomes",
					"type": "address[]"
				}
			],
			"name": "setIncome",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "reward",
					"type": "address"
				}
			],
			"name": "setReward",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_signUser",
					"type": "address"
				}
			],
			"name": "setSignUser",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_system",
					"type": "address"
				},
				{
					"internalType": "bool",
					"name": "state",
					"type": "bool"
				}
			],
			"name": "setSystem",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "signUser",
			"outputs": [
				{
					"internalType": "address",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newOwner",
					"type": "address"
				}
			],
			"name": "transferOwnership",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "storage_",
					"type": "address"
				}
			],
			"name": "updateStorage",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "newImplementation",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "data",
					"type": "bytes"
				}
			],
			"name": "upgradeToAndCall",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "withdrawReleaseToken",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "withdrawStaticToken",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
	],
	"address":"0x13b6660AA76cD715c225E2ee040356b486d916d0"
}